:root {
  --bs-btn-close-color: whitesmoke;
  --bs-btn-close-bg: url('./close-icon.svg');
  --bs-navbar-color: whitesmoke;
  }

body, html {
  margin: 0;
  height: 120vh !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.row-poem-cards-container{
  padding-bottom: 26px !important;

}

.dark body, .dark html {
  margin: 0;
  height: 120vh !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.offcanvas-body .theme-switch, .dark .offcanvas-body .theme-switch{
  margin-left: 0 !important;
  margin-top:4px !important;
}

.offcanvas-body .nav-link{
  color: #1a1a1a !important;
}

.dark .offcanvas-body{
  background-color: #1a1a1a;
}

.dark button.btn-close{
  color: var(--bs-btn-close-color) !important;
  background-image: url('./close-icon.svg') !important;
  
}

.dark .navbar-toggler:hover, 
.dark .navbar-toggler:focus, 
.dark .navbar-toggler:active{
 color: whitesmoke !important;
 box-shadow: 0 0 0 whitesmoke !important;
 border:1px solid whitesmoke !important;
}

.dark .btn-close:hover, 
.dark .btn-close:focus, 
.dark .btn-close:active{
 color: whitesmoke !important;
 box-shadow: 0 0 0 whitesmoke !important;
 border:1px solid whitesmoke !important;
}

.btn-close:hover, 
.btn-close:focus, 
.btn-close:active{
 color: #1a1a1a !important;
 box-shadow: 0 0 0 #1a1a1a !important;
 border:1px solid #1a1a1a !important;
}

.navbar-toggler:hover, 
.navbar-toggler:focus, 
.navbar-toggler:active{
 color: whitesmoke !important;
 box-shadow: 0 0 0 whitesmoke !important;
 border:1px solid whitesmoke !important;
}

.nav-link:focus{
 color: #1a1a1a !important;
 box-shadow: 0 0 0 #1a1a1a !important;
 border:1px solid #1a1a1a !important;
}

.dark .nav-link:focus{
  /* color: #1a1a1a !important; */
  box-shadow: 0 0 0 #ffd43b !important;
  border:1px solid #ffd43b !important;
 }

.form-control:focus{
  color: #1a1a1a !important;
  box-shadow: 0 0 0 #1a1a1a !important;
  border:1px solid #1a1a1a !important;
 }

 .dark button.toggle_btn:focus{
  box-shadow: 0 0 0 #ffd43b !important;
  outline:1px solid #ffd43b !important;
  
  /* border:1px solid #ffd43b !important; */
 }

 .dark a.dropdown-item:focus{
  box-shadow: 0 0 0 #ffd43b !important;
  outline:1px solid #ffd43b !important;
  
  /* border:1px solid #ffd43b !important; */
 }

 .dark .form-control:focus{
  color: #1a1a1a !important;
  box-shadow: 0 0 0 #ffd43b !important;
  border:1px solid #ffd43b !important;
  outline:1px solid #ffd43b !important;
 }
 .offcanvas button.input-search:focus{
  color: #1a1a1a !important;
  background-color: 0 0 0 #1a1a1a !important;
  border:1px solid #1a1a1a !important;
 }

 .dark .offcanvas button.input-search:focus{
  /* color: #1a1a1a !important; */
  background-color: 0 0 0 #1a1a1a !important;
  border:1px solid #ffd43b !important;
  outline:1px solid #ffd43b !important;
 }

 .offcanvas button.input-search:hover{
  color: rgb(213, 213, 213) !important;
  background-color: 0 0 0 #424141 !important;
  border:1px solid #1a1a1a !important;
 }

.dark .navbar-toggler.collapsed .navbar-toggler-icon{
  color: var(--bs-btn-close-color) !important;
  background-image: url('./hamburger.svg') !important;
  
}

.offcanvas-body form.d-flex{
  margin-top: 16px;
}

.dark .offcanvas-header{
  background-color: #595858;
  color: #ffd43b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.dark .card-body{
  background-color: #3a3939;
  color: whitesmoke;
}

.dark .card{
  background-color: #3a3939;
  color: whitesmoke;
}

.dark .navbar>.container, .dark .navbar>.container-fluid, .dark .navbar-nav{
  background-color: #000000 !important;
}


.dark .single-poem-heading{
  /* background-color: #605e5e; */
  color: #ffd43b;
}

.col-single-poem{
  padding-left:0 !important;
  padding-right: 0 !important;
}

.row.m-auto + .row{
  margin-right: 0px !important;
}

.dark .go-back-span{
color:#ffd43b;
}

.row-poem-cards-container{
  align-items: stretch !important;

}

.dark .row-poem-cards-container{
  align-items: stretch !important;

}

.dark .poem-card{
  border: 1px solid #ffd43b;
  height: 100% !important;
}

.poem-card{
  /* border: 1px solid #ffd43b; */
  height: 100% !important;
}

.dark .single-poem-heading{
  text-align: center;
}

.single-poem-heading{
  text-align: center;
}

.card .card-body h4 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 24px;
}

.dark .card .card-body h4 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color:#ffd43b;
}



.card .card-body {
  display: flex;
  flex-direction: column;

}

.dark .card .card-body {
  display: flex;
  flex-direction: column;

}

.card .card-body p {
  flex-grow: 1;
  text-align: center;
}

.dark .card .card-body p {
  flex-grow: 1;
  text-align: center;
}

.card .card-body a{
  text-align: center;
}

.dark .card .card-body a{
  text-align: center;
}


@media screen and (min-width:393px) and (max-width:560px){
  span.about-view-quote, span.about-view-quote-2{
    font-size:20px;
  }
}

@media screen and (max-width:392px) {
  span.about-view-quote, span.about-view-quote-2{
    font-size:20px;
  }

  span.about-view-quote{
    margin-top:22px;
  }

  .row-about-info{
    padding-bottom: 36px;
  }
  em.about-view-quote-author{
    font-size:16px;
  }
}

@media screen and (max-width:991px){
  .dark .offcanv, .dark .offcanvas-body{
    background-color: #292828 !important;
  }

}

@media screen and (min-width:991px){
  form.d-flex{
    margin-top:0 !important;
  }

  .toggle_btn, .theme-switch{
    background-color: #f8f9fa !important;
  }
  
  .dark .toggle_btn, .dark .theme-switch{
    background-color: #000 !important;
  }

  .toggle_btn, .theme-switch{
    background-color: #f8f9fa !important;
  }
  
  .dropdown-toggle, .nav-link{
    background-color: #f8f9fa !important;
  }

  .dark .nav-link{
    background-color: #000 !important;
  }

  /* .page-header .container-fluid{
    background-color: #000 !important;
  } */

  .nav-link:last-child{
    background-color: #000;
    }
  
    .dark .offcanv, .dark .offcanvas-body, .dark .dropdown-toggle, .dark .theme-switch{
      background-color: #000 !important;
  
    }

  button.toggle_btn{
    margin-top:0 !important;
    padding-top: 0 !important;
    margin-left: 16px !important;
  }

   .dark div.dropdown-menu.show,  .dark div.dropdown-menu.show .nav-link, .dark div.dropdown-menu.show a.dropdown-item {
    background-color: #424242 !important;
  }

  
  div.dropdown-menu.show,  div.dropdown-menu.show .nav-link, div.dropdown-menu.show a.dropdown-item {
    background-color: #f3f0f0 !important;
  }
  div.dropdown-menu.show .nav-link:hover, div.dropdown-menu.show a.dropdown-item:hover {
    background-color: #ffffff !important;
    transition: none;
  }
  .dark div.dropdown-menu.show .nav-link:hover {
    background-color: #505050 !important;
    transition: none;
  }
  div.dropdown-menu.show a.dropdown-item{
  padding-left: 0 !important;
  }

 
  div.dropdown-menu.show a.dropdown-item:hover{
    background-color: transparent !important;
  }
}

@media screen and (min-width:393px) and (max-width:769px) {
  .row-about-info{
    padding-bottom: 46px !important;
    padding-top: 16px !important;

  }
  em.about-view-quote-author{
    font-size:16px;
  }
 
}

@media screen and (max-width:769px) {


.card video{
  width:100%;
  height:auto;
}

}