.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:hover{
  color:#1a1a1a !important;
}


.video-image-heading,
.technologies-heading {
   
    background-color: #529fcc;
    
}


.page-header.dark select{
    background-color:#000;
    color:white;
}

.page-header.dark a.dropdown-item, .page-header.dark a.nav-cases, .page-header.dark #collapsible-nav-dropdown{
    background-color:#000;
    color:#fff;
}

.page-header.dark a.dropdown-item:hover, .page-header.dark a.nav-cases:hover{
    background-color:#000 !important;
    color:#fff;
}

.social-container.dark a:hover{
    color:white !important;
}



nav.page-header{
  background-color: #529fcc;
  color: hsl(0, 0%, 100%);
  width:100%;
  
}

nav.page-header.dark{
  background-color: black;
  color: #fff;
}