.main-footer, .footer-404 {
    /* margin: 1rem auto; */
    /* max-width: 800px; */
    /* background-color: #529fcc; */
    color: #000;
    --button-text: #000;
    --button-bg: transparent;
    transition: background-color 0.3s ease;
    background-color: #f8f9fa !important;
    margin-top:20px !important;
  }
  
.main-footer a, .footer-404 a{
color:#141414 !important;
}

.dark .main-footer .rights, .dark .footer-404 .rights{
    color: whitesmoke !important;
}

.dark .main-footer a, .dark .footer-404 a{
    background-color:transparent !important;
    }

.main-footer, .footer-404{
    margin-top: 0px !important;
} 

.main-footer .copyright, .footer-404 .copyright{

    color:#121212 !important;
}

.dark .main-footer .copyright, .dark .footer-404 .copyright{

    color:#ffd43b !important;
}
  .content {
    flex: 1; /* Allows the content to take up available space, pushing the footer to the bottom */
  }

  .footer-links-container{
    display:flex;
    flex-direction: row;
  }

  .dark .footer-links{
    color:#529fcc !important;
    
}

.logo-pulse{
    animation: pulse 1s infinite;
    border-radius: 50%;
    width: 20em;
}

@keyframes pulse{
    from{
      box-shadow: 0 0 0 0 rgba(198, 182, 179, 0.85);
    }

    to{
      box-shadow: 0 0 0 30px rgba(201, 48, 48, 0);
    }
}

.footer-links{
    display:inline;
    margin:0 14px;
    
}

  .dark .copyright{
color: whitesmoke;
  }

  
 
  .main-footer.dark, .footer-404.dark {
    background-color: black;
    color: #fff;
    --button-text: #fff;
    --button-bg: transparent;
    transition: background-color 0.3s ease;
  }


.main-footer, .footer-404 {
    font-family: "Montserrat", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            /* position: fixed; */
    width: 100%;
    bottom: 0;
    /* margin-top: auto; */
    background-color: #529fcc;
    padding-bottom: 16px;
    /* color: white; */
    height:150px;
    /* padding-top:26px; */
    /* margin-top:96px; */
    
}

.main-footer .rights, .footer-404 .rights {
    color: #000000 !important;
}

/* .main-footer.dark p, .footer-404.dark p {
    color: #fff;
} */

.main-footer.dark .social-container, .footer-404.dark .social-container {
    background-color:#000;
}

.main-footer.dark .sidebar-toggler, .footer-404.dark .sidebar-toggler{
    background-color:#000;
    border:1px solid black;
    animation: pulse 1s infinite;
    /* border-radius: 50%; */
    /* width: 20em; */

}

.sidebar-pulse, .dark .sidebar-pulse{
    animation: pulse 1s 3 !important;
    
}

@keyframes pulse{
    from{
      box-shadow: 0 0 0 0 rgba(198, 182, 179, 0.85);
    }

    to{
      box-shadow: 0 0 0 30px rgba(201, 48, 48, 0);
    }
}

.page-header.dark select{
    background-color:#000;
    color:white;
}

.page-header.dark a.dropdown-item, .page-header.dark a.nav-cases, .page-header.dark #collapsible-nav-dropdown{
    background-color:#000;
    color:#fff;
}

.page-header.dark a.dropdown-item:hover, .page-header.dark a.nav-cases:hover{
    background-color:#000 !important;
    color:#fff;
}

.social-container.dark a:hover{
    color:white !important;
}

/* .open-sidebar, .close-sidebar{
    color: black;
} */

.fa-caret-right{
    font-size: 34px;
    color:#529fcc;
    cursor: pointer;
    -webkit-animation: 4s close-sidebar infinite;
            animation: 4s close-sidebar infinite;
}

.dark .main-footer, .dark .footer-404{
    background-color: #1a1a1a !important;
}

.dark .main-footer .copy, .dark .footer-404 .copy{
    color: #ffd43b !important;
}

.sidebar-close{
    
    position:fixed;
    bottom:76%;
    z-index:30;
    left:-6px;
    height:20px;
    width:20px;
    
}

.social-container {
    padding-top: 20px;
    padding-bottom: 20px;
    /* align-items: center; */
    position:fixed;
    bottom:19%;
    left:-37px;
    height:366px;
    width:42px;
    /* width: 100%; */
    opacity:0.8;
    background-color: #529fcc;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -ms-flex-line-pack:space-evenly;
        align-content:space-evenly;
    
    
}

.sidebar-toggler{
    cursor: pointer;
}

.social-container:hover {
    /* left:0; */
    opacity:1;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}
.social-container a{
    margin-bottom: 25px;
    padding:10px;
    
}

.social-information {
    margin: 25px auto 16px;
    color: #1c7488;
}



.dark .projects-main-heading{
    color:#529fcc;
}


.dark .carousel-caption{
    background-color:#000;
    color: whitesmoke;
}

.dark .carousel-heading{
    background-color:#000;
    color: #529fcc;
}

.dark .more{
    background-color:#000;
    color: #529fcc !important;
}

.dark .themeOne{
    background-color:#424242 !important;
    color: #529fcc;
}

.dark .form-heading{
    color:whitesmoke;
}

.dark .captions{
    color: #529fcc;
}

.dark input.w-full{
    background-color: grey !important;
    
}

.dark input.w-full:focus{
    background-color: whitesmoke !important;
    outline: 1px solid #529fcc;
    

}

.dark input.w-full::placeholder{
    color:whitesmoke;
}

.dark input.w-full:focus::placeholder{
    color: black;;
}

.dark .label-required{
    color: yellow; 
    padding-right:2px;
}

.dark .contact-form-tip{
    color: #529fcc;; 
    padding-right:2px;
}

.contact-form-tip span{
    /* color: #529fcc;;  */
    padding-right:3px;
}

.dark .contact-form-tip span{
    /* color: #529fcc;;  */
    padding-right:3px;
}

.dark .form-asterisk{
    color: yellow;
}

.dark .carousel-caption cite{
    background-color:#000;
    color: whitesmoke;
}

/* .dark .footer-404{
    background-color:#000;
    color: whitesmoke;
} */

.dark .video-image-heading, .dark .technologies-heading{
    background-color:#000;
    padding: 15px 0;
}

.dark .skills-paragraphs span{
    background-color:#000;
}

.dark .powered{
    color: whitesmoke;
}

.impressum-links{
    color: #529fcc;
}

.page-header a:hover{
    color: black !important;
}

.dark .page-header{
    background-color: #363434 !important;
}

.dark .dropdown-item, .dark .dropdown-menu.show{
    background-color: #363434 !important;
}

.dark .dropdown-item:hover{
    background-color: transparent !important;
}

.dark .page-header a.dropdown-item:hover{
    background-color:#494747 !important;
}

.dark a.navbar-brand, .dark a.nav-link{
    color: whitesmoke !important;
}

.page-header a.nav-link:hover{
    color: #121212 !important;
}

.dark .page-header a.nav-link:hover{
    background-color: transparent !important;
}

.dark .page-header a.nav-link:hover{
    color: #ffd43b !important;
}

.form-control{
    height:38px;
    
}

.dark .form-control:focus, .dark .form-control:active{
    outline: 1px solid #ffd43b !important;
    border:none !important;
    
}

.input-search{
    height:38px;

}

button.input-search{
    border:1px solid #aaa;
    color: #121212;
}

button.input-search:hover{
    border:1px solid #121212;
    color: #fafafa;
    background-color: #121212;
}

.dark button.input-search{
    border:1px solid #ffffff !important;
    color: #ffffff !important;
    background-color: #121212 !important;
}

.dark button.input-search:focus{
    border:1px solid #fafafa !important;
    color: #fafafa !important;
    background-color: #121212 !important;
}

button.input-search:active{
    border:1px solid #414141 !important;
    color: #d2cece !important;
    background-color: #414141 !important;
}

.dark button.input-search{
    border:1px solid #121212;
    color: #cecbcb;
}

.dark button.input-search:hover{
    border:1px solid #070707;
    color: #e3dede;
    background-color: #070707;
}

.dark button.input-search:active{
    border:1px solid #676666 !important;
    color: #d2cfcf !important;
    background-color: #4c4b4b !important;
}

.dark .fa-envelope:hover,
.dark .fa-skype:hover,
.dark .fa-linkedin:hover,
.dark .fa-twitter:hover,
.dark .fa-github:hover,
.dark .fa-medium:hover,
.dark .fa-dev:hover,
.dark .fa-code:hover,
.dark .fa-up:hover,
.dark .fa-circle:hover,
.dark .fa-clipboard-check:hover,
.dark .projects-carousel-heading,
.dark .projects-more,
.dark .page-header a:hover{
        color:#529fcc !important;
    }
  

.fa-envelope:hover,
.fa-skype:hover,
.fa-linkedin:hover,
.fa-twitter:hover,
.fa-github:hover,
.fa-medium:hover,
.fa-dev:hover {
    color: black;
    -webkit-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    translate: 0px -4px;
    -webkit-transition: color 0.2s ease, translate 0.3s linear;
    -o-transition: color 0.2s ease, translate 0.3s linear;
    transition: color 0.2s ease, translate 0.3s linear;
}

.fa-envelope.contact-icons,
.fa-linkedin.contact-icons {
    color: #529fcc;
}

.fa-envelope.contact-icons:hover,
.fa-linkedin.contact-icons:hover {
    color: #3888b6;
}

.fa-envelope,
.fa-twitter,
.fa-linkedin,
.fa-github,
.fa-medium,
.fa-dev {
    /* padding-right: 15px; */
    font-size: 24px;
    color: white;
}

.fa-medium{
margin-left:-4px;
}

.overview-404 a{
    text-transform: capitalize;
}

@media screen and (max-width: 992px) {
    
.dark .fa-envelope:hover,
.dark .fa-skype:hover,
.dark .fa-linkedin:hover,
.dark .fa-twitter:hover,
.dark .fa-github:hover,
.dark .fa-medium:hover,
.dark .fa-dev:hover{
        color:white;
    }
    .social-container{
        position:static;
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
            -ms-flex-direction:row;
                flex-direction:row;
        padding:0;
        margin:0;
        width:initial;
        height:50px;
        
    }
    .main-footer, .footer-404{
        /* position: fixed; */
    width: 100%;
    bottom: 0;
        height:190px;
        /* margin-top:46px; */
        /* position:absolute;
left:0;
bottom:0;
right:0; */

        
    }
    .dark .main-footer .social-container, .dark .footer-404 .social-container{
        background-color: black;
        
        
        
    }



    .dark .main-footer .rights, .dark .footer-404 .rights{
        color: whitesmoke !important;
    }

    .sidebar-close{
        display:none;
    }
}