*{
    font-family: "Montserrat", sans-serif;
}

@-webkit-keyframes picture-404 {
    0% {
        -webkit-transform: translate(0px, 4px);
                transform: translate(0px, 4px);
    }

    50% {
        -webkit-transform: translate(0px, -4px);
                transform: translate(0px, -4px);
    }

    100% {
        -webkit-transform: translate(0px, 4px);
                transform: translate(0px, 4px);
    }
}

@keyframes picture-404 {
    0% {
        -webkit-transform: translate(0px, 4px);
                transform: translate(0px, 4px);
    }

    50% {
        -webkit-transform: translate(0px, -4px);
                transform: translate(0px, -4px);
    }

    100% {
        -webkit-transform: translate(0px, 4px);
                transform: translate(0px, 4px);
    }
}

.overview-404 {
    margin-left: 20px;
}

.dark .footer-404{
    background-color:#000;
    color: whitesmoke;
}

.picture-404 {
    z-index: -1;
    /* border-radius: 10%; */
    width: 90%;
    max-width: 400px;
    height: auto;
    margin-right: 40px;
    animation: 3s picture-404 infinite;
}

@media screen and (min-width:950px){
    .container-404{
display: flex;
flex-direction:row;


    }
    .picture-404 {
        /* z-index: -1;
        border-radius: 10%; */
        width: 47%;
        /* max-width: 400px; */
        height: auto;
        
       
        /* animation: 3s picture-404 infinite; */ 
    }
    .main-information_container{
        width: 52%;
        padding-left: 50px;
    }
}