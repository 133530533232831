.top-to-btm {
    position: relative;
  }
  
  .icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
  }
  
  .icon-style {
    background-color: #4f4b4b;
    border: 2px solid #000507;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: whitesmoke;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
  }
  
  .icon-style:hover {
    animation: none;
    background: #010d13;
    color: whitesmoke;
    border: 2px solid whitesmoke
    
  }
  

  .dark .icon-style {
    background-color: #4f4b4b;
    border: 2px solid #000507;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: whitesmoke;
    cursor: pointer;
    animation: movebtn 3s ease-in-out infinite;
    transition: all 0.5s ease-in-out;
  }
  
  .dark .icon-style:hover {
    animation: none;
    background: #529fcc;
    color: #000;
    border: 2px solid #000;
  }
  @keyframes movebtn {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(20px);
    }
    50% {
      transform: translateY(0px);
    }
    75% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }