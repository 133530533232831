@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');




html,body {
    font-size: 18px;
    background: #fff;
    color: #aaa
  }
  
  h1,h2 {
    font-weight: 200;
    margin: 0.4em 0;
  }
  h1 { font-size: 3.5em; }
  h2 {
    color: #888;
    font-size: 2em;
  }

  .load-span{
    font-family: "Great Vibes", cursive;
    font-weight: 400;
    font-style: normal;  }
